import React, { useState } from 'react';
import styled from 'styled-components';
import { sendNewsletterSignUp } from 'api/email';
import { Button } from 'components/button';
import { Checkbox } from 'components/checkbox';
import { NonStretchedImage } from 'components/non-stretched-image';
import { SectionTitle } from 'components/section-title';
import { SectionWrapper } from 'components/section-wrapper';
import { Spinner } from 'components/spinner';
import { SECTIONS } from 'constants/sections';
import { graphql, useStaticQuery } from 'gatsby';
import { Input } from 'modules/footer/components/Input';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';
import { validateEmail } from 'utils/utils';

const SContainer = styled.div`
  border: 2px solid ${COLORS.CREAMY};
  display: flex;

  ${MEDIA.max768`
    flex-direction: column-reverse
`};
`;

const SImageContainer = styled.div`
  margin-top: 3rem;
  width: 100%;
`;

const STextColumn = styled.div`
  padding: 0  1.5rem;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap:  1rem;
}

  ${MEDIA.max520`
   padding: 1.75rem;
`};
`;

const SParagraph = styled.p`
  color: ${COLORS.GREY};

  & span {
    font-weight: bold;
  }
`;

const SParagraphSmall = styled.p`
  color: ${COLORS.GREY};
  font-size: 12px;
  font-style: italic;
`;

const SSectionTitle = styled(SectionTitle)`
  text-align: left;
  margin-bottom: 0;
`;

const SForm = styled.form`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const SInput = styled(Input)`
  max-width: none;
`;

const SRow = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const SStatus = styled.p`
  color: ${({ isSent }) => (isSent ? COLORS.GREEN : COLORS.RED)};
`;

export const NewsletterSection = () => {
  const [email, setEmail] = useState('');
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  const [formState, setFormState] = useState('');

  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "newsletter.png" }) {
        childImageSharp {
          gatsbyImageData(width: 470, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  const handleSend = async (e) => {
    e.preventDefault();
    setFormState('loading');
    if (!validateEmail(email)) return;
    const res = await sendNewsletterSignUp({ email, isPrivacyPolicyAccepted });
    if (res.error) {
      setFormState('error');
    } else {
      setFormState('success');
    }
  };

  const resolveFormStatus = () => {
    if (!formState) {
      return null;
    }

    if (formState === 'loading') {
      return <Spinner />;
    }

    if (formState === 'success') {
      return (
        <SStatus isSent>
          Sprawdź swoją skrzynkę pocztową i potwierdź regulamin
        </SStatus>
      );
    }

    return <SStatus isSent>Coś poszło nie tak. Spróbuj ponownie</SStatus>;
  };

  return (
    <SectionWrapper id={SECTIONS.NEWSLETTER.slice(2)}>
      <SContainer>
        <SImageContainer>
          <NonStretchedImage childImageSharp={data.desktop.childImageSharp} />
        </SImageContainer>
        <STextColumn>
          <SSectionTitle title="Dołącz do newslettera" />
          <SParagraph>
            Odbierz voucher na <span>100 zł na dowolny zabieg</span> medycyny
            estetycznej.
          </SParagraph>
          <SForm onSubmit={handleSend}>
            <SInput
              placeholder="wpisz adres e-mail"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <SRow>
              <Button
                text="Zapisz"
                type="submit"
                disabled={formState === 'loading'}
                full
              />
              {resolveFormStatus()}
            </SRow>
            <Checkbox
              id="privacy"
              name="privacy"
              label="Wyrażam zgodę na przesyłanie przez IDEALE Sp. z o.o. (NIP: 5273036809) z siedzibą w Warszawie (dalej jako IDEALE) informacji handlowych dotyczących działalności IDEALE oraz oferowanych przez IDEALE produktów i usług za pomocą środków komunikacji elektronicznej - podany adres e-mail, co wiąże się z używaniem telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem. Administratorem danych osobowych podanych w formularzu jest IDEALE."
              checked={isPrivacyPolicyAccepted}
              required
              onChange={(e) => setIsPrivacyPolicyAccepted(e.target.checked)}
            />
          </SForm>
          <SParagraphSmall>
            Zabieg wykonywany w ramach voucheru nie będzie uwzględniony na
            karcie lojalnościowej
          </SParagraphSmall>
        </STextColumn>
      </SContainer>
    </SectionWrapper>
  );
};
